import React, { useState } from "react";
import PropTypes from "prop-types";
import Adyen from "@containers/adyen";
import RoomChargesForm from "@components/room-charges-form";
import { useDispatch, useSelector } from "react-redux";
import { action as UiActions } from "@store/ui/reducer";
import { actions as PaymentActions } from "@store/payment/reducer";
import { Box } from "@mui/material";
import Loading from "@components/loading";
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { placeOrder } from "@store/checkout/api";
import CheckoutService from "@services/CheckoutService";
import { orderStatus } from "@store/order-status/api";
import { backdropStyle, getTotalPayment, getTotalAmount } from "./constant";
import { reset } from "@store/resetAPI";

const roomChargesPath = "/room-charges/outlet/:outletId/order-type/:orderType";

function PaymentHandler(props) {
  const [isLoading, setLoading] = useState(false);

  // All Hooks declared
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderType } = useParams();
  const { path } = useRouteMatch();

  // All redux selectors
  const {
    orderTotals = {},
    orderDetails,
    orderTypes,
  } = useSelector((state) => state.checkout);
  const [selectedOrderType = {}] = orderTypes.filter(
    ({ orderTypeId }) => orderTypeId == orderType
  );

  const { isDelivery } = selectedOrderType;
  const outlet = useSelector((state) => state.outlet);
  const { tipsAmount, comment, orderFor, items, name, email, checkoutFields } =
    useSelector((state) => state.order);
  const { showTableAndRoomSelector } = useSelector(
    (state) => state.viewOrderPage
  );

  // All de-structured variables
  const { outletId, removeSurcharges } = outlet;
  const { totalPrice } = orderTotals;
  // This will be used by payment method adyen
  const totalPayment = getTotalPayment({ tipsAmount, totalPrice });
  // This will be used by fetch apis
  const totalAmount = getTotalAmount({ tipsAmount, totalPrice });

  const { order = {} } = orderDetails;
  const { orderId } = order;

  const getPayload = () => {
    const payload = {
      outlet,
      tipsAmount,
      comment,
      orderTypeId: parseInt(orderType),
      totalPaid: totalAmount,
      items,
      ...checkoutFields,
    };
    if (isDelivery) {
      payload["locationId"] = orderFor?.value;
    }

    if (Boolean(name)) {
      payload["firstName"] = name;
    }
    if (Boolean(email)) {
      payload["email"] = email;
    }
    if (removeSurcharges) {
      payload["removeSurcharge"] = true;
    }

    return payload;
  };

  const goToConfirmationPage = () => {
    console.log("Into goToConfirmationPage");
    history.push(
      `/order-confirmation/outlet/${outletId}/order-type/${orderType}`
    );
  };

  const goToPreviousPage = () => {
    history.push(`/view-order/outlet/${outletId}/order-type/${orderType}`);
  };

  const startOrder = () => {
    console.log("Into to start order");
    const payload = getPayload();
    return dispatch(placeOrder(payload));
  };

  const onPaymentFail = (args) => {
    const message =
      args?.message || "Incorrect payment details. Please try again ";
    const passedOrderId = args?.orderId;
    setLoading(false);
    dispatch(PaymentActions.reset());
    return dispatch(
      orderStatus({
        orderId: passedOrderId || orderId,
        statusTypeName: "FailedPayment",
      })
    ).then((res) => {
      notifyError(message);
    });
  };

  const confirmOrderStatus = async (attempt = 1, maxAttempts = 50) => {
    if (attempt > maxAttempts) {
      return Promise.reject(
        `Exceeded the maximum of ${maxAttempts} attempts. Order validation failed.`
      );
    }

    const response = await CheckoutService.getOrderDetails({ orderId });

    const [order = {}] = response?.orders || [];

    console.log(`Attempt ${attempt}: Order valid:`, order?.orderValid);

    if (order?.orderValid) {
      console.log("Order is valid, stopping the recursion.");
      return Promise.resolve("Order validation succeeded!");
    } else {
      console.log(`Order is not valid, retrying... (Attempt ${attempt + 1})`);
      // Recursively call the function if order is not valid, incrementing the attempt count
      return await confirmOrderStatus(attempt + 1, maxAttempts);
    }
  };

  const onPaymentSuccess = (args) => {
    const { orderId: passedOrderId } = args || {};

    console.log({ passedOrderId, orderId });
    return dispatch(
      orderStatus({
        orderId: passedOrderId || orderId,
        statusTypeName: "Paid",
      })
    )
      .then((res) => {
        console.log("onPaymentSuccess then");
        goToConfirmationPage();
        return res;
      })
      .catch((error) => {
        console.log("Error in confirming order status", error);
        if (error.code === "UNABLE_TO_COMMUNICATE_TO_POS") {
          notifyError(error.message);
          goToPreviousPage();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const notifyError = (message) => {
    dispatch(UiActions.setSnackBarError(message));
  };

  console.log("Rendering Payment container =>", totalAmount);

  const childProps = {
    outlet,
    totalPayment: totalPayment,
    totalAmount: totalAmount,
    orderPayload: getPayload(),
    key: totalAmount,
    orderId: orderId,
    goToPreviousPage,
    startOrder: startOrder,
    notifyError: notifyError,
    setLoading: setLoading,
    onPaymentSuccess: onPaymentSuccess,
    onPaymentFail: onPaymentFail,
    confirmOrderStatus,
  };

  return (
    <Box position="relative" className="payment-container-wrapper">
      {React.cloneElement(props.children, {
        ...childProps,
      })}
      {isLoading && (
        <Box sx={backdropStyle}>
          <Loading />
        </Box>
      )}
    </Box>
  );
}

PaymentHandler.propTypes = {};

export default PaymentHandler;
