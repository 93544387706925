import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  continueButtonError: null,
  showTableAndRoomSelector: true,
};
const viewOrderPage = createSlice({
  name: "viewOrderPage",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateContinueButtonError: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    updateShowTableAndRoomSelector: (state, { payload }) => ({
      ...state,
      showTableAndRoomSelector: payload,
    }),
  },
});

export const actions = viewOrderPage.actions;

export default viewOrderPage;
