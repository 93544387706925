import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TabPanel from "@components/tab-panel";
import { Grid, Typography, Box, Divider, Button } from "@mui/material";
import Currency from "@components/currency";
import { actions as MenuActions } from "@store/menu/reducer";
import { useHistory } from "react-router-dom";

import { actions as OrderActions } from "@store/order/reducer";

import "./styles.css";
import ImageContainer from "@components/image-component";
import DynamicResizeHandler from "./DynamicResizeHandler";
import { filterMenu } from "@root/store/menu/api";
import Allergens from "@components/allergens";
import DynamicAllergensOverflowHandler from "./DynamicAllergensOverflowHandler";
import ItemContainer from "./ItemContainer";

function SubCategoryMenu(props) {
  const { menu, selectedMenu, selectedTab, selectedSubCategoryTab } =
    useSelector((state) => state.menu);
  const { stickyHeader } = useSelector((state) => state.ui);
  const { orderTypes, tenantId } = useSelector((state) => state.outlet);
  const { selectedItemIds = [] } = useSelector((state) => state.order);
  const { attributes } = useSelector((state) => state.outlet);
  const { isDigitalMenu } = attributes;

  const history = useHistory();

  const dispatch = useDispatch();

  const selectItem = (item) => {
    dispatch(MenuActions.updateSelectedItem(item));
  };

  const addItem = (item) => {
    dispatch(
      OrderActions.addItem({
        ...item,
        comment: "",
        selectedModifiers: [],
        totalPrice: item?.price,
        modifierTotalAmount: 0,
        quantity: 1,
        modifiedUnitPrice: item?.price,
      })
    );
  };

  const getSafeArray = (item) => {
    if (item) {
      return item;
    }
    return [];
  };

  const shouldHideItem = ({ visible, ...item }) => {
    if (!visible) {
      return true;
    }
  };

  const getOverflow = () => {
    const selectedCategory = menu.categories[selectedTab];
    return selectedCategory?.categories.length > 1 ? "scroll" : null;
  };

  const handleItemSelect = (item) => {
    const { items } = item;
    if (items.length > 0) {
      return selectItem(item);
    }
    return addItem(item);
  };

  const getSelectedItemCount = ({ itemId }) => {
    return selectedItemIds.filter((item) => item === itemId);
  };

  const isMenuNotAvailable =
    !getSafeArray(selectedMenu?.categories).length &&
    !getSafeArray(menu?.categories).length;

  if (isMenuNotAvailable || !orderTypes.length) {
    return (
      <Grid container rowSpacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ fontSize: 35 }}>
            Sorry!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1">This menu is not available.</Typography>
        </Grid>
        {tenantId && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "30px",
              }}
              onClick={() =>
                tenantId ? history.push(`/tenant/${tenantId}`) : null
              }
            >
              See Other Menu's
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  if (!getSafeArray(selectedMenu.categories).length) {
    return (
      <Grid container rowSpacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ fontSize: 35 }}>
            Sorry!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1">No result found</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box
      id="menu-container-2"
      sx={{
        paddingBottom: "90px",
        overflowY: getOverflow(),
      }}
    >
      {getSafeArray(selectedMenu?.categories).map((category, index) => {
        return (
          <Grid
            container
            sx={{ mb: 2 }}
            index={index}
            key={category.categoryId}
            className="sub-category-menu"
            id={category.categoryId}
          >
            {getSafeArray(category?.categories).map(
              (subCategory, subCategoryIndex) => {
                subCategoryIndex = subCategoryIndex + 1;
                return (
                  <Box
                    key={subCategory.categoryId}
                    className="arpit"
                    style={{
                      width: "100%",
                    }}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "24px",
                      p: 1,
                      pt: 1.5,
                      mb:
                        category?.categories.length > 1 &&
                        subCategoryIndex !== category?.categories.length
                          ? 2
                          : 0,
                    }}
                    id={subCategory.categoryId}
                  >
                    <Grid item xs={12}>
                      <Box sx={{ pt: "3.5px", pb: "3.5px" }}>
                        <Typography variant="h3">{subCategory.name}</Typography>
                      </Box>
                      <Divider />
                    </Grid>
                    {getSafeArray(subCategory?.items).map((item, subIndex) => {
                      if (shouldHideItem(item)) {
                        return null;
                      }

                      const selectCount = getSelectedItemCount(item);
                      return (
                        <Fragment key={item.itemId}>
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <ItemContainer
                                item={item}
                                selectCount={selectCount}
                                handleItemSelect={handleItemSelect}
                                isDigitalMenu={isDigitalMenu}
                                onClick={() => {
                                  if (item.transformedAttributes?.outOfStock) {
                                    return 0;
                                  }
                                  selectItem(item);
                                }}
                              />
                            </Grid>
                          </Grid>

                          {subCategory.items.length !== subIndex + 1 && (
                            <Grid item xs={12}>
                              <Box>
                                <Divider />
                              </Box>
                            </Grid>
                          )}
                        </Fragment>
                      );
                    })}
                  </Box>
                );
              }
            )}
          </Grid>
        );
      })}
    </Box>
  );
}

export default SubCategoryMenu;
