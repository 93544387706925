import md5 from "md5";

export const addItemsHelper = (state, payload) => {
  let { normalizedItems } = state;
  const { quantity, totalPrice, cost, ...restPayload } = payload;
  const uniqueId = md5(JSON.stringify(restPayload));

  if (normalizedItems[uniqueId]) {
    const item = normalizedItems[uniqueId];
    const { cost = 0, totalPrice: oldTotalPrice, quantity: oldQuantity } = item;
    const { quantity = 1 } = payload;
    const totalItemPrice = cost * quantity;

    const newTotalPrice = oldTotalPrice + totalItemPrice;
    const newQuantity = oldQuantity + quantity;
    console.log([
      cost,
      oldTotalPrice,
      oldQuantity,
      quantity,
      totalItemPrice,
      newTotalPrice,
    ]);

    normalizedItems = {
      ...normalizedItems,
      [uniqueId]: { ...item, quantity: newQuantity, totalPrice: newTotalPrice },
    };
  }

  const deNormalizedItems = Object.values(normalizedItems);

  if (!normalizedItems[uniqueId]) {
    const cost = payload.cost || payload.price;
    console.log(payload.cost, payload.price);
    const newPayload = { ...payload, uniqueId, cost };
    deNormalizedItems.push(newPayload);
    normalizedItems = { ...normalizedItems, [uniqueId]: newPayload };
  }

  const newTotalPrice = deNormalizedItems.reduce(
    (acc, item) => acc + item.totalPrice,
    0
  );

  return {
    items: deNormalizedItems,
    totalPrice: newTotalPrice,
    normalizedItems,
  };
};
