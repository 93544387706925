import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { actions as menuActions } from "@store/menu/reducer";
import { UiAction } from "@store/ui/reducer";
import { IconButton } from "@mui/material";
import Icon from "@components/icons";
import "./style.css";
import CategoriesWrapper from "./Categories";

function Categories(props) {
  const [openFilter, toggleFilter] = useState(null);
  const { selectedTab } = useSelector((state) => state.menu);
  const menuData = useSelector((state) => state.menu);
  const stickyHeader = useSelector((state) => state.ui?.stickyHeader);
  const menu = menuData.menu || [];

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(menuActions.updateSelectedTab(newValue));
    dispatch(menuActions.updateSelectedSubCategoryTab(0));
  };

  useEffect(() => {
    dispatch(menuActions.updateSelectedTab(0));
    dispatch(menuActions.updateSelectedSubCategoryTab(0));
    dispatch(UiAction.toggleStickyHeader(false));
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     scrollToCategory(selectedTab);
  //   }, 100);
  // }, [selectedTab]);

  if (!stickyHeader) {
    return null;
  }

  return (
    <CategoriesWrapper
      {...{ toggleFilter, selectedTab, handleChange, menu, openFilter }}
    />
  );
}

export default Categories;
